import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Layout } from './Layout';
import { Box } from './elements';
import Carousel from './carousel/Carousel';

const HomePage = ({ data }) => {
  const { site_logo: siteLogo } = data.prismicNavigation.data;
  const { localFile: bgLocalFile } = data.prismicNavigation.data.bg_nav;
  const { body: navigation } = data.prismicNavigation.data;
  const navigationPack = { navigation, bgLocalFile };
  const { items } = data.prismicPageBodyFeatured;

  return (
    <>
      <Layout navigationPack={navigationPack} siteLogo={siteLogo}>
        <Box
          style={{
            position: 'relative',
            width: '100%',
            minHeight: '100%',
            background:
              'linear-gradient(90deg, rgba(199,209,209,1) 0%, rgba(233,255,239,1) 100%)'
          }}
        >
          <Carousel images={items} />
        </Box>
      </Layout>
    </>
  );
};

HomePage.propTypes = {
  data: PropTypes.object.isRequired
};

export default HomePage;

export const query = graphql`
  fragment FeaturedCarouselFragment on Query {
    prismicPageBodyFeatured {
      primary {
        featured_carousel
      }
      items {
        featured_title {
          text
        }
        description {
          html
        }
        featured_image {
          alt
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`;

import React from 'react';
import shortid from 'shortid';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const BulletsContainer = styled.div`
  position: absolute;
  width: 30%;
  bottom: 30%;
  left: 50%;
  display: flex;
  transform: translateX(-50%);
  justify-content: space-around;

  @media (min-width: 769px) {
    display: none;
  }
`;

const Icon = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  opacity: ${({ flag }) => (flag ? '1' : '0.5')};
  background-color: #000;
  transition: opacity 0.6s;
`;

const Bullets = ({ titles, activeIdx }) => (
  <BulletsContainer>
    {titles.map((item, idx) => (
      <Icon
        className="fas fa-dot-circle"
        key={shortid.generate()}
        flag={idx === activeIdx}
        // onClick={() => manualSlideChange(index)}
      />
    ))}
  </BulletsContainer>
);

Bullets.propTypes = {
  titles: PropTypes.array.isRequired,
  activeIdx: PropTypes.number.isRequired
};

export default Bullets;

import React from 'react';
import shortid from 'shortid';
import styled from '@emotion/styled';
import Swipe from 'react-easy-swipe';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

const CarouselContainer = styled.ul`
  display: flex;
  flex-direction: row;
  width: auto;
  height: 100%;
  margin: 0;
  padding: 0;
  transform: translate3d(
    ${({ offset }) => (offset ? `${-(offset * 100)}%` : 0)},
    0,
    0
  );
  transition-timing-function: cubic-bezier(1, 0.2, 0.6, 0.8);
  opacity: 0.6;

  li {
    min-width: 100%;
  }

  .gatsby-image-wrapper {
    height: 100%;
  }
`;

const SlideShowImages = ({ images, activeIdx, handleChange }) => {
  const handleSwipeLeft = () => {
    handleChange(activeIdx + 1);
  };

  const handleSwipeRight = () => {
    handleChange(activeIdx - 1);
  };

  return (
    <Swipe
      style={{ width: '100%', height: '100%' }}
      onSwipeLeft={handleSwipeLeft}
      onSwipeRight={handleSwipeRight}
    >
      <CarouselContainer offset={activeIdx}>
        {images.map(el => (
          <li key={shortid.generate()}>
            <Img fluid={el.featured_image.localFile.childImageSharp.fluid} />
          </li>
        ))}
      </CarouselContainer>
    </Swipe>
  );
};

SlideShowImages.propTypes = {
  images: PropTypes.array.isRequired,
  activeIdx: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default SlideShowImages;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';

import Bullets from './Bullets';
import SlideShowImages from './SlideShowImages';
import SlideShowDescription from './SlideShowDescription';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100vh;
  transform: translateX(-50%);
  animation: ${fadeIn} 1s;
  overflow: hidden;
  display: flex;
`;

const Carousel = ({ images, slideshowSpeed }) => {
  const [current, setCurrent] = useState(0);

  const handleChange = () => {
    setCurrent(idx => {
      if (idx < images.length - 1) {
        return idx + 1;
      }
      return idx % (images.length - 1);
    });
  };

  useEffect(() => {
    const id = setInterval(handleChange, slideshowSpeed);
    return () => {
      clearInterval(id);
    };
  }, []);

  return (
    <div>
      <SlideShowDescription descriptions={images} activeIdx={current} />
      <Container>
        <SlideShowImages
          images={images}
          activeIdx={current}
          handleChange={handleChange}
        />
        <Bullets titles={images} activeIdx={current} />
      </Container>
    </div>
  );
};

Carousel.propTypes = {
  images: PropTypes.array.isRequired,
  slideshowSpeed: PropTypes.number
};

Carousel.defaultProps = {
  slideshowSpeed: 6000
};
export default Carousel;

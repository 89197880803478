import { graphql } from 'gatsby';

import HomePage from '../components/1.HomePage';

export default HomePage;

export const query = graphql`
  {
    ...LayoutFragment
    ...FeaturedCarouselFragment
  }
`;
